import React from "react"
import { navigate } from "gatsby"
import { FaMapMarkerAlt, FaRegEnvelope, FaPhoneAlt } from "react-icons/fa"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: {
        products: "0",
      },
      errors: {},
    }
  }

  handleValidation() {
    let fields = this.state.fields
    let errors = {}
    let formIsValid = true

    //Name
    if (!fields["name"]) {
      formIsValid = false
      errors["name"] = "Name is required"
    }

    if (typeof fields["name"] !== "undefined") {
      if (!fields["name"].match(/^[a-zA-Z\s]+$/)) {
        formIsValid = false
        errors["name"] = "Please fill with letters only"
      }
    }

    //Email
    if (!fields["email"]) {
      formIsValid = false
      errors["email"] = "Email is required"
    }

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@")
      let lastDotPos = fields["email"].lastIndexOf(".")

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false
        errors["email"] = "Email is not valid"
      }
    }

    if (!fields["message"]) {
      formIsValid = false
      errors["message"] = "Message is required"
    }

    if (fields["products"] === "0") {
      formIsValid = false
      errors["products"] = "Product of interest is required"
    }

    this.setState({ errors: errors })
    return formIsValid
  }

  handleSubmit = async e => {
    e.preventDefault()

    if (this.handleValidation()) {
      const form = e.target
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state.fields,
        }),
      })
        .then(() => navigate(form.getAttribute("action")))
        .catch(error => alert(error))
    }
  }

  handleInputChange(field, e) {
    let fields = this.state.fields
    fields[field] = e.target.value
    this.setState({ fields })
  }

  render() {
    return (
      <section className="contacts section" id="contacts">
        <div className="bd-container">
          <div className="contacts__container bd-grid">
            <div className="contacts__data">
              <h2 className="contacts__title">
                PT. Whrilindo Indonesia Perkasa
              </h2>

              <div className="contacts__body">
                <div className="contacts__icon">
                  <i>
                    <FaMapMarkerAlt className="contacts__icon" />
                  </i>
                </div>
                <div className="contacts__wrapper">
                  <p className="contacts__description">
                    Kawasan Bintang Industri 2
                  </p>
                  <p className="contacts__description">
                    Blok H2-07, Tg. Uncang - Batam
                  </p>
                </div>
              </div>
              <div className="contacts__body">
                <div className="contacts__icon">
                  <i>
                    <FaRegEnvelope className="contacts__icon" />
                  </i>
                </div>
                <div className="contacts__wrapper">
                  <p className="contacts__description">
                    <a
                      href="mailto:whrilingindonesia@gmail.com"
                      className="contacts__email"
                    >
                      whrilingindonesia@gmail.com
                    </a>
                  </p>
                </div>
              </div>
              <div className="contacts__body">
                <div className="contacts__icon">
                  <i>
                    <FaPhoneAlt className="contacts__icon" />
                  </i>
                </div>
                <div className="contacts__wrapper">
                  <p className="contacts__description">+628 539 091 8888</p>
                  <p className="contacts__description">+628 228 310 2890</p>
                </div>
              </div>
              <p className="contacts__description">
                <strong>Opening Hours: </strong>Mon - Sat, 08:00 - 16:30 WIB
              </p>
            </div>

            <form
              name="contact"
              id="contact-form"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              action="/success/"
              onSubmit={this.handleSubmit}
              className="contacts__form"
            >
              <input type="hidden" name="form-name" value="contact" />
              <p hidden>
                <label>
                  Don't fill this out:{" "}
                  <input name="bot-field" onChange={this.handleChange} />
                </label>
              </p>
              <h2 className="contacts__title">Contact Us Today!</h2>
              <div className="contact__subcontainer">
                <input
                  name="name"
                  id="contact-form-name"
                  type="text"
                  onChange={this.handleInputChange.bind(this, "name")}
                  value={this.state.fields["name"]}
                  placeholder="Enter your full name"
                  className="contact__input"
                />
                <span className="contacts__error">
                  {this.state.errors["name"]}
                </span>
              </div>
              <div className="contact__subcontainer">
                <input
                  name="email"
                  id="contact-form-email"
                  type="email"
                  onChange={this.handleInputChange.bind(this, "email")}
                  value={this.state.fields["email"]}
                  placeholder="Enter your email"
                  className="contact__input"
                />
                <span className="contacts__error">
                  {this.state.errors["email"]}
                </span>
              </div>
              <div className="contact__subcontainer">
                <select
                  id="products"
                  name="products"
                  className="contact__dropdown"
                  onChange={this.handleInputChange.bind(this, "products")}
                  value={this.state.fields["products"]}
                  autoComplete="false"
                >
                  <option value="0" disabled>
                    Product of Interest
                  </option>
                  <option value="1">Yoota - Turbine Ventilator</option>
                  <option value="2">Plywood</option>
                </select>
                <span className="contacts__error">
                  {this.state.errors["products"]}
                </span>
              </div>
              <div className="contact__subcontainer">
                <textarea
                  name="message"
                  id="contact-form-message"
                  onChange={this.handleInputChange.bind(this, "message")}
                  value={this.state.fields["message"]}
                  cols="0"
                  rows="10"
                  placeholder="Message"
                  className="contact__input"
                ></textarea>
                <span className="contacts__error">
                  {this.state.errors["message"]}
                </span>
              </div>

              <button
                type="submit"
                className="contacts__button button"
                aria-label="enquiry"
              >
                ENQUIRY
              </button>
            </form>
          </div>
        </div>
      </section>
    )
  }
}
