import React from "react"
import Navbar from "../components/navbar"
import Contacts from "../components/contacts"
import Footer from "../components/footer"
import Seo from "../components/seo"

const Contact = () => {
  return (
    <div>
      <Seo title="Contact" />
      <Navbar />
      <Contacts />
      <Footer />
    </div>
  )
}

export default Contact
